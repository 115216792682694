import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { emptyData } from '../utils/util';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Contact = () => {
  const [formData, setFormData] = useState(emptyData);
  const [submissionMessage, setSubMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (
      formData.name !== '' ||
      formData.email !== '' ||
      formData.phone !== '' ||
      formData.subject !== '' ||
      formData.message !== ''
    ) {
      try {
        setIsSending(true);
        await emailjs.send(
          'service_xhgkaue',
          'template_mzib3b8',
          { ...formData, reply_to: 'walicbeverage@gmail.com' },
          'RcYOXeQB519nINgaZ'
        );
        setFormData(emptyData);
        setIsSending(false);
        toast.success('Message sent successfully');
      } catch (error) {
        console.error(e);
        setIsSending(false);
        toast.error('Oops! something went wrong');
      }
    } else {
      setSubMessage('All input fields are required');
    }
  };

  return (
    <>
      {' '}
      <section id='contact' className='relative py-10 md:py-[90px]'>
        <div className='absolute top-0 left-0 z-[-1] h-1/2 w-full bg-[#0f3667] lg:h-[45%] xl:h-1/2'></div>
        <div className='container px-4'>
          <div className='-mx-4 flex flex-wrap items-center'>
            <div className='w-full px-4 lg:w-7/12 xl:w-8/12'>
              <div className='ud-contact-content-wrapper'>
                <div className='ud-contact-title mb-12 lg:mb-[150px] text-white'>
                  <h2 className='text-[25px] font-bold leading-[30px]'>
                    CONTACT US
                  </h2>
                  <span className='mb-5 text-md leading-[34px]'>
                    Have a message for us? <br />
                    Contact us to place your order!
                  </span>
                </div>
                <div className='mb-12 mt-[22px] lg:pt-[33px] flex flex-wrap justify-between items-center lg:mb-0'>
                  <div className='mb-8 flex w-[330px] max-w-full'>
                    <div className='mr-3 text-[32px] text-[#fb2436]'>
                      <svg
                        width='29'
                        height='35'
                        viewBox='0 0 29 35'
                        className='fill-current'
                      >
                        <path d='M14.5 0.710938C6.89844 0.710938 0.664062 6.72656 0.664062 14.0547C0.664062 19.9062 9.03125 29.5859 12.6406 33.5234C13.1328 34.0703 13.7891 34.3437 14.5 34.3437C15.2109 34.3437 15.8672 34.0703 16.3594 33.5234C19.9688 29.6406 28.3359 19.9062 28.3359 14.0547C28.3359 6.67188 22.1016 0.710938 14.5 0.710938ZM14.9375 32.2109C14.6641 32.4844 14.2812 32.4844 14.0625 32.2109C11.3828 29.3125 2.57812 19.3594 2.57812 14.0547C2.57812 7.71094 7.9375 2.625 14.5 2.625C21.0625 2.625 26.4219 7.76562 26.4219 14.0547C26.4219 19.3594 17.6172 29.2578 14.9375 32.2109Z' />
                        <path d='M14.5 8.58594C11.2734 8.58594 8.59375 11.2109 8.59375 14.4922C8.59375 17.7188 11.2187 20.3984 14.5 20.3984C17.7812 20.3984 20.4062 17.7734 20.4062 14.4922C20.4062 11.2109 17.7266 8.58594 14.5 8.58594ZM14.5 18.4297C12.3125 18.4297 10.5078 16.625 10.5078 14.4375C10.5078 12.25 12.3125 10.4453 14.5 10.4453C16.6875 10.4453 18.4922 12.25 18.4922 14.4375C18.4922 16.625 16.6875 18.4297 14.5 18.4297Z' />
                      </svg>
                    </div>
                    <div>
                      <h5 className='max-sm:text-white  mb-[2px] md:mb-[4px] text-lg font-semibold'>
                        Our location
                      </h5>
                      <p className='text-base text-black max-sm:text-white'>
                        9701 Avenue D, Brooklyn, NY, 11236
                      </p>
                    </div>
                  </div>

                  <div className='mb-8 flex w-[330px] max-w-full'>
                    <div className='mr-3 text-[32px] text-[#fb2436]'>
                      <svg
                        width='34'
                        height='25'
                        viewBox='0 0 34 25'
                        className='fill-current'
                      >
                        <path d='M30.5156 0.960938H3.17188C1.42188 0.960938 0 2.38281 0 4.13281V20.9219C0 22.6719 1.42188 24.0938 3.17188 24.0938H30.5156C32.2656 24.0938 33.6875 22.6719 33.6875 20.9219V4.13281C33.6875 2.38281 32.2656 0.960938 30.5156 0.960938ZM30.5156 2.875C30.7891 2.875 31.0078 2.92969 31.2266 3.09375L17.6094 11.3516C17.1172 11.625 16.5703 11.625 16.0781 11.3516L2.46094 3.09375C2.67969 2.98438 2.89844 2.875 3.17188 2.875H30.5156ZM30.5156 22.125H3.17188C2.51562 22.125 1.91406 21.5781 1.91406 20.8672V5.00781L15.0391 12.9922C15.5859 13.3203 16.1875 13.4844 16.7891 13.4844C17.3906 13.4844 17.9922 13.3203 18.5391 12.9922L31.6641 5.00781V20.8672C31.7734 21.5781 31.1719 22.125 30.5156 22.125Z' />
                      </svg>
                    </div>
                    <div>
                      <h5 className='mb-[2px] md:mb-[4px] text-lg font-semibold max-sm:text-white '>
                        How can we help?
                      </h5>
                      <p className='text-base text-black max-sm:text-white '>
                        cemeagwali@yahoo.com
                      </p>
                      <p className='text-base text-black max-sm:text-white '>
                        walicbeverage@gmail.com
                      </p>
                    </div>
                  </div>
                  {/* <div className='mb-8 flex w-[330px] max-w-full'> */}
                  {/* <div className='mr-3 text-[32px] text-[#fb2436]'>
                      <WhatsAppIcon
                        sx={{ width: '29px', height: '35px' }}
                        className=''
                      />
                    </div> */}
                  {/* <div>
                      <h5 className='mb-[2px] md:mb-[4px] text-lg font-semibold max-sm:text-white '>
                        WhatsApp
                      </h5>
                      <p className='text-base text-black max-sm:text-white '>
                        <a
                          href='https://api.whatsapp.com/send?phone=5162335678'
                          className='text-blue-600 underline'
                        >
                          Click to send WhatsApp message
                        </a>
                      </p>
                    </div> */}
                  {/* </div> */}
                  <div className='mb-8 flex w-[330px] max-w-full'>
                    <div className='mr-3 text-[32px] text-[#fb2436]'>
                      <svg
                        width='34'
                        height='25'
                        fill='none'
                        viewBox='0 0 34 25'
                        className='fill-current'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z'
                        />
                      </svg>
                    </div>
                    <div>
                      <h5 className='mb-[2px] md:mb-[4px] text-lg font-semibold max-sm:text-white'>
                        Phone
                      </h5>
                      <p className='text-base text-black max-sm:text-white '>
                        516-233-5678
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-5/12 xl:w-4/12 border-[1px] border-[#0f3667]'>
              <div
                className='wow fadeInUp rounded-lg bg-white py-10 px-8 shadow-testimonial sm:py-12 sm:px-10 md:p-[60px] lg:p-10 lg:py-12 lg:px-10 2xl:p-[60px]'
                data-wow-delay='.2s
              '
              >
                <h3 className='mb-8 text-2xl font-semibold md:text-[26px] text-black'>
                  Send us a message
                </h3>
                <form onSubmit={sendEmail}>
                  <p className='mb-8 text-[14px] font-semibold text-red-500'>
                    {submissionMessage}
                  </p>
                  <div className='mb-6'>
                    <label
                      htmlFor='fullName'
                      className='block text-sm text-black'
                    >
                      Full Name*
                    </label>
                    <input
                      type='text'
                      name='name'
                      placeholder='Full Name'
                      className='w-full border-b border-[#0f3667] py-4 focus:border-primary focus:outline-none'
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </div>
                  <div className='mb-6'>
                    <label htmlFor='email' className='block text-sm text-black'>
                      Email*
                    </label>
                    <input
                      type='email'
                      name='email'
                      placeholder='hello@yourmail.com'
                      className='w-full border-b border-[#0f3667] py-4 focus:border-primary focus:outline-none'
                      onChange={handleChange}
                      value={formData.email}
                    />
                  </div>
                  <div className='mb-6'>
                    <label htmlFor='phone' className='block text-sm text-black'>
                      Phone*
                    </label>
                    <input
                      type='text'
                      name='phone'
                      placeholder='000 000 00000'
                      className='w-full border-b border-[#0f3667] py-4 focus:border-primary focus:outline-none'
                      onChange={handleChange}
                      value={formData.phone}
                    />
                  </div>
                  <div className='mb-6'>
                    <label
                      htmlFor='email'
                      className='block text-[13px] text-black'
                    >
                      Subject*
                    </label>
                    <input
                      type='text'
                      name='subject'
                      placeholder='Email Subject'
                      className='w-full border-b border-[#0f3667] py-4 focus:border-primary focus:outline-none text-black'
                      onChange={handleChange}
                      value={formData.subject}
                    />
                  </div>
                  <div className='mb-6'>
                    <label
                      htmlFor='message'
                      className='block text-sm text-black'
                    >
                      Message*
                    </label>
                    <textarea
                      name='message'
                      rows='1'
                      placeholder='Type your message here'
                      onChange={handleChange}
                      value={formData.message}
                      className='w-full resize-none border-right border-b border-[#0f3667]  py-4 focus:border-primary focus:outline-none'
                    ></textarea>
                  </div>
                  <div className='mb-0'>
                    <button
                      type='submit'
                      className='inline-flex items-center justify-center rounded bg-black py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-[#fb2436]'
                      disabled={isSending}
                    >
                      {isSending ? 'Sending....' : 'Send message'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
