import React from 'react';
// import companyLogo from '../images/companyLogo.png';
import pepsiLogo from '../images/pepsiTransparent.png';
import Walic from '../images/walic.png';
import pepsiBG from '../images/mtnDew.jpeg';
const Footer = () => {
  return (
    <div
      className='w-full h-[auto] bg-cover bg-center bg-no-repeat object-cover md:bg-cover md:bg-right'
      style={{
        backgroundImage: `url(${pepsiBG})`,
      }}
    >
      <div className='w-full h-full  bg-[rgba(0,0,0,0.7)] md:bg-[rgba(0,0,0,0.9)] flex  justify-between items-center border-t  border-dashed border-white flex-wrap'>
        <p className='text-white p-6 flex justify-space-around w-auto items-center gap-x-2'>
          <span className=''>
            {' '}
            <img className='h-[60px]' src={Walic} alt='Walic logo missing' />
          </span>
          <span className='ml-[1px]'> Walic Beverage Distributor Inc </span>
        </p>
        <p className='text-white p-8'>Copyright &copy; 2022</p>
        <p className='text-white p-8 flex justify-space-around w-auto items-center gapx-6'>
          <span>
            {' '}
            <img
              className='h-[60px]'
              src={pepsiLogo}
              alt='pepsi logo missing'
            />
          </span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
